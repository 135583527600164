import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <h3 className="title is-family-secondary">Observation Guide</h3>
        <div className="content" id="tips">
          <p>You can find the <a href="/HC-ESN.pdf" target="_blank"><strong>Observation Tracking Document here</strong></a>.  The document is a preview of the Observation Submission Form for submitting after your dockets.</p>
          <p>Below are some tips for observation.</p>
          <ul className="listing">
            <li>
              <h4>Getting Good Data</h4>
              <p>Get familiar with the data we are collecting and know what information or keywords to listen for.</p>
            </li>
            <li>
              <h4>Informing yourself</h4>
              <p>Go over the docket before the hearings begin.
                If you want to be connecting information in real-time from the docket, knowing what is on there will help you find it quicker.
              </p>
            </li>
            <li>
              <h4>Setting up for success</h4>
              <p>
                Know how you will be recording your information.
                Court can go really quickly and if you are fumbling between papers, pages, or tabs, you may lose some important facts.
              </p>
            </li>
            <li>
              <h4>Identifying the case in question</h4>
              <p>
              The Judge will always call out the name of the two parties when starting a new case hearing.
              This may be the only identifiable information referencing the case at hand.
              </p>
            </li>
            <li>
              <h4>Understanding the courtroom</h4>
              <p>
              Hearings can drag or fly right by. Every session will be different.
              Be fully present and pay close attention to the courtroom to fully follow along with what is happening (and what isn’t that should be.)
              </p>
            </li>
            <li>
              <h4>Sharing your observations</h4>
              <p>
                Fill out the observation submission form after you observe. Make sure your information is correct and complete before you submit. 
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="section has-background-light">
      <div className="container">
        <div className="content" id="after">
          <h3 className="title is-family-secondary">After Observation</h3>
          <p>
            Remember to fill out one Observation Submission Form, linked in your email, for each case you observed this week,
            and don’t forget to log the time you spend watching court below.
          </p>
          <div className="lds-dual-ring">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc--Alh7i8cZ59opb6levUTZIsXthUfo1WCKPyQE4kSB40y4w/viewform?embedded=true"
              width="100%"
              height="1564"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Sign Up to Join ESN"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
